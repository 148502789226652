<template>
    <div>
        <div>
            <div class="scrolling-wrapper" v-if="loading == false">
                <div class="card-scroll click" v-for="user in users" :key="user.id" @click="openPurchase(user)">
                    <b-avatar :src="user.image " size="6rem"></b-avatar>
                    <h4 class="text-white mt-2">
                        {{ user.username }}
                    </h4>
                </div>
            </div>
            <Loading v-else />
        </div>
        <b-modal v-model="purchaseSignal" title="Purchase signal" v-if="userTemp != null" centered hide-footer>
            <Purchase :user="userTemp" @close="purchaseSignal = false" />
        </b-modal>
    </div>
</template>
<script>
import Purchase from './Purchase.vue'
import { mapActions, mapState } from 'vuex'
export default {
    components: {
        Purchase
    },
    data () {
        return {
            loading: false,
            purchaseSignal: false,
            userTemp: null
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('signal', ['getSignalsUsers']),
        getData () {
            this.loading = true
            this.getSignalsUsers().then(() => {
                this.loading = false
            })
        },
        openPurchase (user) {
            this.userTemp = user
            this.purchaseSignal = true
        }
    },
    computed: {
        ...mapState('signal', ['users'])
    }
}
</script>
<style>
    .scrolling-wrapper {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }
    .card-scroll {
        display: inline-block;
        margin-right: 20px;
        width: 280px;
        margin-bottom: 20px;
    }
    ::-webkit-scrollbar {
        height: 5px;
    }
    
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
    }
    
    ::-webkit-scrollbar-thumb {
        height: 5px;
        background-color: #E3C87A;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background-color: #E3C87A;
    }
    
    ::-webkit-scrollbar:vertical {
        display: none;
    }
</style>